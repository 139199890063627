import React from "react";
import OfferCard from "../reusables/OfferCard";
import domain from "../../assets/images/icons2/domain-www-icon.svg";
import logo from "../../assets/images/icons2/paint-icon.svg";
import slogan from "../../assets/images/icons2/strike-protest-icon.svg";
import hosting from "../../assets/images/icons2/website-hosting-icon.svg";
import seo from "../../assets/images/icons2/website-seo-icon.svg";
import content from "../../assets/images/icons2/web-content-creator-icon.svg";
import responsive from "../../assets/images/icons2/cross-platform-icon.svg";
import pages from "../../assets/images/icons2/web-pages-icon.svg";
import email from "../../assets/images/icons2/email-address-icon.svg";
import profile from "../../assets/images/icons2/company-enterprise-icon.svg";
import ssl from "../../assets/images/icons2/ssl-icon.svg";
import map from "../../assets/images/icons2/road-route-map-icon.svg";
import support from "../../assets/images/icons2/customer-service-icon.svg";

const OfferBody = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-body margin-auto padding-16">
              <div className="center">
                <p className="offer-notice gray">
                  NB: Your website will be done in 2 weeks.<br></br>
                  This Website Design offer Package goes for{" "}
                  <strong className="gray">$999</strong>
                  <br></br>
                  Fee can increase or decrease depending on client's needs and
                  haves.
                </p>
              </div>
              <ul className="offer-container flex">
                <OfferCard img={domain} offer="Domain Name">
                  <p>
                    This is what identifies your website on the web e.g
                    ntuna.com
                  </p>
                </OfferCard>
                <OfferCard img={logo} offer="Logo Design">
                  <p>
                    This is stand out mark of ownership and connection for your
                    brand with the public
                  </p>
                </OfferCard>
                <OfferCard img={slogan} offer="Slogan/One Liner">
                  <p>
                    That magical statement that tells your prospects and
                    customers about you
                  </p>
                </OfferCard>
                <OfferCard img={hosting} offer="Hosting">
                  <p>
                    Free hosting for a year, to make your website available
                    &amp; accessible to visitors 24/7
                  </p>
                </OfferCard>
                <OfferCard img={seo} offer="Search Engine Optimization">
                  <p>
                    To make your website visible in search engines like Google
                    and rank higher in search results
                  </p>
                </OfferCard>
                <OfferCard
                  img={content}
                  offer="Content Creation &amp; Copywriting"
                >
                  <p>
                    Researched and well structured content based on your target
                    audience personas
                  </p>
                </OfferCard>
                <OfferCard img={responsive} offer="Responsive Design">
                  <p>
                    To make your website maintain the same beauty on all devices
                    it is accessed
                  </p>
                </OfferCard>
                <OfferCard img={pages} offer="Unlimited Non-feature Web Pages">
                  <p>
                    Researched, well written and SEO rich non-feature web pages
                    as your website requires
                  </p>
                </OfferCard>
                <OfferCard img={email} offer="Business Email Addresses">
                  <p>
                    These are customized email addresses based on your domain
                    name e.g info@yourdomain.com
                  </p>
                </OfferCard>
                <OfferCard img={profile} offer="Setup Google Business Profile">
                  <p>
                    To influence how your business appears on google search
                    results and google maps
                  </p>
                </OfferCard>
                <OfferCard img={ssl} offer="SSL Certificate">
                  <p>
                    To make your website and visitors site safe and secure from
                    hackers
                  </p>
                </OfferCard>
                <OfferCard img={map} offer="Google Map">
                  <p>
                    To add a google map to your website directing to your
                    business location
                  </p>
                </OfferCard>
                <OfferCard
                  img={support}
                  offer="Free Support &amp; Maintainance"
                >
                  <p>
                    This is free for the 90 days from the time we handed over
                    your project
                  </p>
                </OfferCard>
              </ul>
              <div className="section-cta section-spacing-20 no-padding-bottom">
                <a
                  href="https://form.jotform.com/210374256613047"
                  className="button uppercase bold"
                >
                  {" "}
                  Take Web Design Offer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferBody;
