import React from "react";
import Faq from "../reusables/Faq";

const FaqsBody = () => {
  return (
    <section className="section section-spacing-50">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content margin-auto padding-16">
              <ul className="faqs-container">
                <Faq faq="How Do I Pay for your services?">
                  <p>
                    We have several ways of funds remittance including Mobile
                    Money and Bank Transfer.
                  </p>
                </Faq>
                <Faq faq="How much do you charge for a website design?">
                  <p>
                    We have a{" "}
                    <a href="/webdesign-offer">website design offer</a> that
                    goes for $999. But our charges depend on the specification
                    and needs of your project.
                  </p>
                </Faq>
                <Faq faq="What can a website really do for me or my business?">
                  <p>
                    A Website is Your Online Home. It is up 24/7 to Tell the
                    World about YOU, Your Business, Your NGO, Your Company, Your
                    brand etc. when you are a way. It Sells You to new clients,
                    lures new prospects to Buying From You and wins you New
                    Fans.
                  </p>
                </Faq>
                <Faq faq="Is the lack of clarity of the content on my website costing me?">
                  <p>
                    Yes. You are really losing with a website that doesn't
                    contribute to your sales or clarity because visitors just
                    get confused or don't care about what you're saying because
                    of how you say it.
                  </p>
                </Faq>
                <Faq faq="Why do I/we need a website?">
                  <p>
                    There are so many reasons why you need a website, and lets
                    tell you some;
                  </p>
                  <p>
                    1. Your customers/donors/investors first search online for
                    potential businesses to buy from or organizations and
                    charities to donate to and deal with? How would you feel
                    knowing and being sure they will get information about you
                    from your own website?
                  </p>
                  <p>
                    2. People hardly trust your business unless it is a
                    household name. There resort is to search for you online and
                    see if you invested in it before they invest in you with
                    their trust and money.
                  </p>
                  <p>
                    3. A great number of potential customers/donors can't know
                    why they need your product, service or donate to your
                    organization. How much business you are not getting because
                    you have not paid attention to your online presence?
                  </p>
                </Faq>

                <Faq faq="What is the purpose of a website?">
                  <p>
                    Most brands need a website that serves all or any of the
                    three purposes:{" "}
                  </p>
                  <p>1. To Create Sales;</p>
                  <p>2. To Clarify Their Message;</p>
                  <p>3. To Have an Online Presence on the Web;</p>
                  <p>
                    Creating sales or Clarifying your message for action may not
                    be the main reason you are in business, but it is the main
                    reason you will stay in business.
                  </p>
                  <p>
                    Your website should be a sales machine, an Information base
                    with well structured, well clarified and convincing message
                    to influence action not an online version of your brochure.
                  </p>
                </Faq>
                <Faq faq="How important are words on a website?">
                  <p>
                    A great website can be worth hundreds of thousands or even
                    millions of dollars though we design for you the best at an
                    affordable charge. But, some brands (Companies, Celebrities,
                    Public Figures, Political Parties, Churches, NGOs and
                    Charities) are getting their websites wrong and they don't
                    know why.
                  </p>
                  <p>
                    Most of us intuitively know our website is important, so we
                    pay somebody thousands of dollars to design it for us.
                  </p>
                  <p>
                    Inevitably, whoever designs our website is more concerned
                    with colors, images, and feel than they are with the words
                    you should use. While colors and images are fine, it's words
                    that sell things, and clarifies your message best.
                  </p>
                </Faq>

                <Faq faq="Will I hit two birds with one stone by using NTUNA.COM for our website project?">
                  <p>
                    Yes. Because your website will not only be a great work of
                    art but also dramatically increase your sales and clarify
                    your message to your audience.
                  </p>
                  <p>
                    Too many businesses spend thousands on a website that is
                    ultimately simply a work of art and doesn't affect their
                    sales at all, nor clarifies their message.
                  </p>
                  <p>
                    At NTUNA.COM, we believe it's absolutely possible for a
                    website to be a great work of art and also dramatically
                    increase your sales and clarify your message so people can
                    take your intended action on your message because they
                    understand what you are talking about. And that's what we
                    will do for you and help you achieve.
                  </p>
                </Faq>
              </ul>
              <div className="section-cta section-spacing-20 no-padding-bottom">
                <a
                  href="https://form.jotform.com/210374256613047"
                  className="button uppercase bold"
                >
                  {" "}
                  Get Your Free Quotation
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqsBody;
